
@import 'styles/rsuite/rsuite-table.scss';
@import '../node_modules/bootstrap/scss/_functions.scss';
@import '../node_modules/bootstrap/scss/_mixins.scss';
@import '../node_modules/bootstrap/scss/_variables.scss';


@media screen and (max-width: $bp-tablet)  {
  .custom-responsive {
    thead,
    th {
      display:none;
    }
    tr,
    td,
     {
      display:block;
      width:100%;
    }
    td {
        border-bottom: none !important;
        border-top: none !important;
    }

  }
  .rs-table {
    height: auto !important;

  /*  .rs-table-mouse-area {
      height: auto !important;
    }
    .resize-sensor {
          pointer-events: all !important;
          display: none;
    }*/
    .rs-table-row-header {
      display: none;
    }

    .rs-table-body-row-wrapper {
      top:0px !important;
      height: auto !important;

      .rs-table-body-wheel-area {
        position: revert !important;
        height: auto !important;
        min-height: auto !important;
      }
    }

    .rs-table-row {
      height:auto !important;

      border-bottom: 1px solid #dee2e6;
      transform: none !important;
      min-width: auto !important;
    }
    .rs-table-cell-group {
        pointer-events: all !important;
    }
    .rs-table-cell {

      pointer-events: all !important;
      height:auto !important;
      width:100% !important;
      border: none !important;
      mouse

      &:first-of-type {
          padding-top: 1rem;
      }
      &:last-of-type {
          padding-bottom: 1rem;
      }

      .rs-table-cell-content {
          height: auto !important;
        .btn {
          float:left;
        }
      }
      &.text-col {
        .rs-table-cell-content {
          white-space: inherit;
          width: calc(100% - 16px) !important;
        }
      }
    }


  }


}

@media (max-width: 1000px)  {
  .pagination {
    margin-top: 1rem!important;
  }
}
.rs-table {

  &.rs-table-word-wrap {
    .rs-table-cell-content {
          white-space: break-spaces;
          word-break: break-word;
          vertical-align: top !important;
    }
  }

  .break-spaces {
    .rs-table-cell-content {
      white-space: break-spaces;
      word-break: break-word;
    }
  }

  .rs-table-row-header {
    .rs-table-cell {
        background: none;
        font-weight: bold;
    }

  }

  &.text-col {
    .rs-table-cell-content {
      white-space: inherit;
      width: calc(100% - 16px) !important;


    }
  }
  .rs-table-cell-header-icon-sort
  {
    &:after {
      font-family: -webkit-pictograph;
      content: "\f0dc";
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      padding-left: 0.4em;
    }
  }

  .rs-table-cell-header-icon-sort-asc
  {
    &:after {
      color: #005cbf;
      content: "\f0de";
    }
  }
  .rs-table-cell-header-icon-sort-desc
  {
    &:after {
      color: #005cbf;
      content: "\f0dd";
    }
  }
  .rs-table-cell {
    border: 1px solid #dee2e6;

    .btn {
      &.hasSpinner {
        &:disabled {
          padding-right: 0px;
        }
      }
    }

    &.clamp-col {
      .rs-table-cell-content {
        @media (min-width: $bp-tablet)  {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          overflow: hidden;
          white-space: break-spaces;
          text-overflow: initial;
        }
        @media print   {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          overflow: hidden;
          white-space: break-spaces;
          text-overflow: initial;
        }
      }
    }

    .badge {
      width:100%;
      display: block;
      margin-top:0px;
    }
  }
}

.rs-table-hover {

  .rs-table-row-header {
    background: none;
    &:hover {
      background: none;
      .rs-table-cell-group,  .rs-table-cell {
         background: none;
      }
    }
  }
}

.table-striped {

  .rs-table-body-row-wrapper {
    .rs-table-row{
      &:nth-of-type(odd)  {
        .rs-table-cell {
          background: rgba(0,0,0,.05);
        }
      }
      &:hover {
        .rs-table-cell {
          background: rgba(0,0,0,.075);
          cursor: pointer;
        }
      }
    }
  }
}

.double-rowed {
    tbody+tbody {
        border-top : none !important;
    }

    tbody {
      border-bottom: 1px solid #dee2e6;

      &:nth-of-type(odd) {
        tr {
          background-color: rgba(0,0,0,.05);
        }
      }
      &:hover {
        tr {
          background-color: rgba(0,0,0,.075);
        }
      }
      tr {

        th,
        td {
          border: none;
        }
      }

    }
}

@media print {
  .rs-table {
      height:auto !important;
  }
  .rs-table-header-row-wrapper {
    height:40px;
  }

  .rs-table-row-header  {
    width:100% !important;
    min-width: auto !important;
    .rs-table-cell-header:nth-of-type(1) {
      .rs-table-cell {
        width:17% !important;
      }
    }
    .rs-table-cell-header:nth-of-type(2) {
      .rs-table-cell {
        left:17% !important;
        width:6% !important;
      }
    }
    .rs-table-cell-header:nth-of-type(3) {
      .rs-table-cell {
        left:23% !important;
        width:9% !important;
      }
    }
    .rs-table-cell-header:nth-of-type(4) {
      .rs-table-cell {
        left:32% !important;
        width:69% !important;
      }
    }
    .rs-table-cell-header:nth-of-type(5) {
      display: none;
    }
  }

  .rs-table-body-row-wrapper  {
    height:auto !important;
    .rs-table-body-wheel-area {
      position: initial !important;
        width:100% !important;;
    }
    .rs-table-row {
      width:100% !important;
      height:auto !important;
      min-width: auto !important;
      transform: none !important;
      display: flex;
      flex-direction: column;
      position: initial;
      .rs-table-cell-group {
          flex-grow: 1;
          display: flex;

          .rs-table-cell {
            height:auto !important;
            position: initial;
            .rs-table-cell-content {
                height:auto !important;
                width:100% !important;
            }

          }
        }
    }
    .rs-table-cell:nth-of-type(1) {
        width:17% !important;
    }
    .rs-table-cell:nth-of-type(2) {
      left:17% !important;
      width:6% !important;
    }
    .rs-table-cell:nth-of-type(3) {
      left:23% !important;
      width:9% !important;
    }

    .rs-table-cell:nth-of-type(4) {
      left:32% !important;
      width:69% !important;

    }

    .rs-table-cell:nth-of-type(5) {
      display: none;
    }

  }
  .table-list {
    .rs-table-cell:nth-of-type(4) {
      left:32% !important;
      width:69% !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      &:before {
        flex-grow: 0;
        order: 2;
        content: ' ';
        border:solid 1px grey;
        display: block;
        width:30px;
        height:30px;
        border-radius: 2px;
        margin-right: 8px;
      }
      &:after {
        content: ' ';
        flex-grow: 1;
        order: 3;
        border:solid 1px grey;
        display: block;
        width:120px;
        height:70px;
        border-radius: 2px;
        margin-right: 8px;
      }
      &.constrain-print {
          .rs-table-cell-content {
            width:50% !important;
          }
      }
      .rs-table-cell-content {
        order: 1;
        width:50% !important;
      }
    }
  }
}


//
// Basic Bootstrap table
//
.md-table {
  & > div > p {
    line-height: 1.5rem;
    margin-bottom: 0px;
  }
  table {
    width: 100%;
    margin-bottom: $spacer;
    color: $table-color;
    background-color: $table-bg; // Reset for nesting within parents with `background-color`.

    th,
    td {
      padding: $table-cell-padding;
      vertical-align: top;
      border-top: $table-border-width solid $table-border-color;
    }

    thead th {
      vertical-align: bottom;
      border-bottom: (2 * $table-border-width) solid $table-border-color;
    }

    tbody + tbody {
      border-top: (2 * $table-border-width) solid $table-border-color;
    }
  }




  // Border versions
  //
  // Add or remove borders all around the table and between all the columns.

  table {
    border: $table-border-width solid $table-border-color;

    th,
    td {
      border: $table-border-width solid $table-border-color;
    }

    thead {
      th,
      td {
        border-bottom-width: 2 * $table-border-width;
      }
    }
  }



  // Zebra-striping
  //
  // Default zebra-stripe styles (alternating gray and transparent backgrounds)

  table {
    tbody tr:nth-of-type(#{$table-striped-order}) {
      background-color: $table-accent-bg;
    }
  }


  // Hover effect
  //
  // Placed here since it has to come after the potential zebra striping

  table {
    tbody tr {
      @include hover() {
        color: $table-hover-color;
        background-color: $table-hover-bg;
      }
    }
  }


  // Table backgrounds
  //
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.

  @each $color, $value in $theme-colors {
    @include table-row-variant($color, theme-color-level($color, $table-bg-level), theme-color-level($color, $table-border-level));
  }

  @include table-row-variant(active, $table-active-bg);

}
